.MyChart{
  width: 40vw;
  height: 40vh;
  margin: 20px auto;
}

.ChartBackground {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.ChartContainer {
  margin-bottom: 5em;
  background-color: #fefefe;
  border-radius: 25px;
  box-shadow: 0px 1px 4px 0px rgb(0, 0, 0, 0.5);
}

#chart {
  width: 100%;
  height: 100%;
}

#t01 tr:nth-child(even) {
  background-color: #fff;
}
#t01 tr:nth-child(odd) {
  background-color:  #fff;
}
#t01 th {
  color: black;
  background-color: #e0e0e0;
}


.Canvas {
  height: 40vh;
  width: 40vw;
  min-height: 200px;
  min-width: 400px;
  margin: auto;
  min-width: 100px;
}

.Gradient {
  height: 40vh;
  width: 15vw;
  min-height: 200px;

}

.Table {
  margin-top: 0;
}


.RowElement {
  margin:auto;
}
.Logo {
  max-height: 120px;
  max-width: 400px;
}

.Building {
  max-height: 120px;
  /* max-width: 80%; */
  width: 50%;
  margin: 20px auto;
  border: 3px solid yellowgreen;
  border-radius: 15px;
}

.Layout {
  display: flex;
  flex-direction: column;
}

.Row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  align-content: space-between;
}

.Button {
  font-size: 21px;
  color: #fff;
  border: 0;
  background-color: #23799e!important;
  border-color: #23799e!important;
  border-radius: 3px;
  -webkit-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
  margin: 20px;
  width: 75%;
}

h1 {
  font-size: 20px;
}


.Input {
  font-size: 16px;
  color: #BABFC9;
  border: 0;
  background-color: #fff;
  border-color: #fff;
  border-radius: 3px;
  -webkit-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
}

.chart-select {
  text-align: center;
}

.d-flex >.chart-nav-icon:first-child {
  padding-left: 0.1em;
  padding-right: 0.2em;
}

.d-flex >.chart-nav-icon:last-child {
  padding-left: 0.2em;
  padding-right: 0.1em;
}

.btn.chart-nav-icon {
  font-size: 3em;
  line-height: 0em;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #3578bd;
}

.chart-nav-icon.disabled {
  color: #BABFC9;
}

.btn-group-custom {
  margin: 1em 2em;
}

.btn-group-custom > .btn {
  width: 50%;
}

.btn-group-custom > .btn:first-child {
  border-radius: 0.25rem 0 0 0.25rem;
}

.btn-group-custom > .btn:last-child {
  border-radius: 0 0.25rem 0.25em 0;
}

.btn.btn-brand, .btn.btn-outline-brand.active {
  border: 1px solid #3578bd;
  background: #3578bd;
  color: #fff;
}

.btn.btn-outline-brand {
  border: 1px solid #3578bd;
  background: #fff;
  color: #212529;
}

.Table input {
  width: 100%!important;
}