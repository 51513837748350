@use '../../../_base.scss';

.alertsBackground {
    background-color: base.$bg-color;
    border-radius: 25px;
    box-shadow: base.$block-box-shadow;
    cursor: pointer;
}

.totalAlerts {
    text-align: center;
    margin: auto;
    justify-content: center;
    align-items: center;
    font-size: 6vh;
}

.alertProgress {
    background-color: rgba(231, 60, 63, 0.5);
    height: 100%;
    margin-bottom: 25%;
    display: inline-block;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-color: rgba(231, 60, 63, 1);
    border-style: solid;
    border-width: 1px 0 1px 1px;
}

.workingProgress {
    background-color: rgba(86, 185, 71, 0.5);
    height: 100%;
    margin-bottom: 25%;
    display: inline-block;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-color: rgba(86, 185, 71, 1);
    border-style: solid;
    border-width: 1px 1px 1px 0;
}
