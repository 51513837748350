@use '../../../_base.scss';

.graphBackground {
    background-color: base.$bg-color;
    border-radius: 25px;
	box-shadow: base.$block-box-shadow;
    width: 100%;
}

.waterUseGraph {
    height: 90%;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
}

.expandedWaterUseGraph {
    height: 500px;
    width: 95vw;
}

.modal-100w {
    width: 100vw;
    max-width: 100vw !important;
}