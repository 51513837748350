@use '../../../_base.scss';

.blocksLabel {
    margin-top: 10px;
    margin-left: 15px;
    // color: base.$theme-ligt-blue;
    // color: #84878c;
    color: base.$theme-black;
    font-size: 2vh;
}
