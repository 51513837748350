@use '../../../_base.scss';

.topUsingBackground {
    background-color: base.$bg-color;
    border-radius: 25px;
    box-shadow: base.$block-box-shadow;
}

.topUsingExportBtn {
    margin-right: 15px;
    color: #BABFC9;
    font-size: 2vh;
}
.colPlaceholder {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    text-align: center;
    font-weight: bold;
    color: #BABFC9;
}