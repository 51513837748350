@use '../../_base.scss';

.roomViewSearch-top-row {
    height: 20%;
    width: calc(100vw - 100px);
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
}
.roomViewSearch-middle-row {
    height: 50%;
    width: calc(100vw - 100px);
    justify-content: center;
    align-items: center;
    margin: 0px 0px 0px 10px !important;
    // margin: 0px !important;
}
.roomViewSearch-bottom-row {
    height: 30%;
    width: calc(100vw - 100px);
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
}
.roomView-top-row {
    height: 30%;
    width: calc(100vw - 100px);
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
    h2 {
        margin-bottom: 0 !important;
    }
}
.roomView-middle-row {
    height: 30%;
    width: calc(100vw - 100px);
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
}
.roomView-bottom-row {
    height: 30%;
    width: calc(100vw - 100px);
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
}
.roomView-inner-top-row {
    height: 25%;
    padding-bottom: 0;
    margin-bottom: 0;
    width: calc(100vw - 100px);
    justify-content: center;
    align-items: center;
}
.roomView-inner-bottom-row {
    height: 60%;
    width: calc(100vw - 100px);
    justify-content: center;
    align-items: center;
}
.roomSearchForm {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.roomSearchFormContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.roomSearchInnerContainer {
    background-color: white;
    background-color: base.$bg-color;
    border-radius: 25px;
    box-shadow: base.$block-box-shadow;
    height: 80%;
    // width: calc(100vw - 100px);
    display: grid;
    align-items: center;
    justify-content: center;
    // margin: 0px !important;
    margin: 0px 0px 0px 10px !important;
}
.roomSearchButton {
    font-size: 21px;
    color: #ffffff;
    border: 0;
    background-color: base.$theme-blue !important;
    border-color: base.$theme-blue !important;
    border-radius: 3px;
    transition: all 250ms ease-in-out;
    width: 75%;
}
.roomViewGraphBlock {
    display: flex;
    height: 100%;
    margin: 0px !important;
}
.roomViewDetailBlock {
    display: flex;
    height: 70%;
    padding-bottom: 15px;
    padding-top: 20px;
}

@media all and (max-width: 650px) {
    .roomViewSearch-middle-row {
        width: 100%;
        margin: 0;
    }
    .roomSearchInnerContainer {
        background-color: white;
        background-color: base.$bg-color;
        border-radius: 25px;
        box-shadow: base.$block-box-shadow;
        height: 90%;
        // width: calc(100vw - 100px);
        width: 90%;
        display: grid;
        align-items: center;
        justify-content: center;
        // margin: 0px !important;
        // margin: 0px !important;
    }
    .roomView-top-row, .roomView-middle-row, .roomView-bottom-row {
        width: 100%;
        margin-left: 0;
    }
}

@media all and (min-width: 320px) and (max-width: 415px) {
    .roomViewSearch-top-row {
        height: 10%;
        width: calc(100vw - 100px);
        justify-content: center;
        align-items: center;
        margin-left: 0px !important;
    }
    .roomViewSearch-middle-row {
        height: 60%;
        width: calc(100vw - 100px);
        justify-content: center;
        align-items: center;
        margin-left: 0px !important;
    }
    .roomSearchInnerContainer {
        h2 {
            font-size: 25px !important;
        }
    }
    .roomViewSearch-bottom-row {
        height: 20%;
        width: calc(100vw - 100px);
        justify-content: center;
        align-items: center;
        margin-left: 0px !important;
    }
}

@media all and (min-height: 320px) and (max-height: 415px) {
    .roomViewSearch-middle-row {
        height: 80%;
        width: calc(100vw - 100px);
        justify-content: center;
        align-items: center;
        margin-left: 0px !important;
    }
    .roomSearchInnerContainer {
        h2 {
            font-size: 20px !important;
        }
    }
    .roomView-top-row {
        height: 60vh;
    }
    .roomView-middle-row{
        height: 60vh;
    }
    .roomViewDetailBlock {
        height: 100%;
    }
}

@media all and (max-width: 415px) {
    .roomSearchInput {
        width: 200px !important;
    }
    .roomSearchButton {
        width: 200px !important;
    }
}
@media all and (max-width: 330px) {
    .roomSearchInput {
        width: 180px !important;
    }
    .roomSearchButton {
        width: 180px !important;
    }
    .roomSearchInnerContainer {
        height: 90%;
    }
    .roomViewSearch-top-row {
        height: 10%;
        width: calc(100vw - 100px);
        justify-content: center;
        align-items: center;
        margin-left: 0px !important;
    }
    .roomViewSearch-middle-row {
        height: 80%;
        width: calc(100vw - 100px);
        justify-content: center;
        align-items: center;
        margin-left: 0px !important;
    }
    .roomViewSearch-bottom-row {
        height: 10%;
        width: calc(100vw - 100px);
        justify-content: center;
        align-items: center;
        margin-left: 0px !important;
    }
}
