body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif !important;
}

.location-profile-table {
  margin-bottom: 50px !important;
}

#location-profile-info-table {
  border-collapse: collapse;
}

#location-profile-info-table > tbody > tr > td {
  padding-top: 1em;
  padding-bottom: 1em;
}

.location-status-info-table > tbody > tr > td {
  padding-bottom: 25px !important;
  width: 50%;
}

#gallon-usage-table th,
#gallon-usage-table td {
  text-align: center;
  width: 10%;
}

#carbon-saved th,
#carbon-saved td {
  text-align: center;
  width: 33%;
}

.highlight-row:hover {
  background-color: lightyellow !important;
}

@media (max-width: 1325px) {
  .col-sm-4 {
    width: 100% !important;
  }
}

.ui-combobox-list {
  display: none;
}

.ui-combobox-toggle.ui-corner-all {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ui-combobox {
  position: relative;
}

.ui-combobox-toggle {
  position: absolute;
  top: -1px;
  bottom: 0;
  right: 0;
  width: 14px;
  height: 100%;
  margin-right: 0;
  padding-bottom: 1px;
  /* adjust styles for IE 6/7 */
  *height: 1.7em;
  *top: 0.1em;
}

.ui-combobox-toggle:hover {
  cursor: default;
}

.ui-autocomplete .ui-state-hover {
  background-color: #3399ff !important;
  background-image: none !important;
  color: White;
}

.ui-autocomplete.ui-menu .ui-menu-item a {
  padding: 2 0 0 0 !important;
  line-height: 1 !important;
}

.ui-autocomplete.ui-widget {
  font-family: Sans-Serif !important;
  font-size: 1em !important;
}

.ui-autocomplete.ui-corner-all {
  border-radius: 0 !important;
}

.ui-menu-item:hover {
  background-color: yellow !important;
}

#ui-id-1 {
  background-color: white;
  list-style-type: none;
}

.ui-helper-hidden-accessible {
  display: none !important;
}

.update-var-input {
  width: 100px !important;
}

.update-set-checkbox,
#update-toggle-all,
#update-toggle-pending,
#update-toggle-not-pending {
  width: auto !important;
}
.table-update {
  width: 75% !important;
}
.table-update td {
  width: 100px !important;
  text-align: center;
}

.led-box {
  height: 30px;
  width: 33%;
  margin: 10px 0;
  float: left;
}

.led-box p {
  font-size: 12px;
  text-align: center;
  margin: 1em;
}

.led-red {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
  -webkit-animation: blinkRed 0.5s infinite;
  -moz-animation: blinkRed 0.5s infinite;
  -ms-animation: blinkRed 0.5s infinite;
  -o-animation: blinkRed 0.5s infinite;
  animation: blinkRed 0.5s infinite;
}

.led-yellow {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #ff0 0 2px 12px;
  -webkit-animation: blinkYellow 1s infinite;
  -moz-animation: blinkYellow 1s infinite;
  -ms-animation: blinkYellow 1s infinite;
  -o-animation: blinkYellow 1s infinite;
  animation: blinkYellow 1s infinite;
}

.led-green {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89ff00 0 2px 12px;
}

.led-blue {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #24e0ff;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px, #3f8cff 0 2px 14px;
}

.led-orange {
  margin: 0 auto;
  width: 24px;
  height: 24px;
  background-color: #ff7f00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px, #ff7f00 0 2px 14px;
}

.fade.show {
  opacity: 1;
}
.fade {
  opacity: 0;
}

@media (min-width: 2000px) {
  .container {
    max-width: 1900px !important;
  }
}

/* General Styles for Each Page */
.pageOuterContainer {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
}

.pageWrap {
  height: calc(100vh - 70px);
  width: 100vw;
  margin: 0px 0px 0px 70px !important;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

@media all and (max-width: 650px) {
  .pageWrap {
    height: calc(100vh - 70px);
    width: 100vw;
    margin: 0px 0px 0px 0px !important;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }
}

// @media all and (max-width: 450px) {
//   .sidebarIcon {
//     display: block;
//     padding-bottom: 30px !important;
//   }
//   .dashboardTimeButtons {
//     float: right;
//     margin-right: 0px !important;
//     padding-top: 5px !important;
//   }
//   .dashboardTopBar {
//     height: 200px !important;
//   }
// }
// @media all and (max-height: 700px) {
//   .sidebarIcon {
//     display: block;
//     padding-bottom: 30px !important;
//   }
// }
