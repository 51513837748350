@use '../../../_base.scss';

.gallonsLeakedBackground {
    background-color: base.$bg-color;
    border-radius: 25px;
	box-shadow: base.$block-box-shadow;
}

.totalGallonsLeaked {
    text-align: center;
    margin: auto;
    justify-content: center;
    align-items: center;
    font-size: 6vh;
    line-height: 1em;
}

.totalGallonsLeakedCost {
    margin-top: 0.5em;
    line-height: 1em;
    font-size: 0.4em;
}